<template>
  <div class="content">
    <div class="columns is-mobile">
      <div class="column">
        <p class="subtitle has-text-dark">
          {{ title }}
        </p>
      </div>
      <div class="column is-narrow">
        <a class="has-text-danger" @click="close">Close</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFormHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$mxp.track('close_contact_form')
      return this.$modal.close()
    }
  }
}
</script>
